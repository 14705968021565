import { turnOffInternalAuth } from "../TurnOffInternalAuth";

let activeInternalAuth = false;

if (!turnOffInternalAuth) {
  // If not behind front door or local
  if (process.env.REACT_APP_ENVIRONMENT === "DEV" || process.env.REACT_APP_ENVIRONMENT === "SIT") {
    activeInternalAuth = true;
  }
}

export const enableInternalAuth = activeInternalAuth;
