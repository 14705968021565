import { RacwaInternalAuth } from "raci-react-library-npe";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { OAUTH_AUTHORITY, OAUTH_CLIENT_ID } from "./shared/constants";
import { enableInternalAuth } from "./shared/EnableInternalAuth";

let App: any;

const AppInternal = require("./App").default;

if (enableInternalAuth) {
  App = () => (
    <RacwaInternalAuth authority={OAUTH_AUTHORITY} clientId={OAUTH_CLIENT_ID} redirectUri={window.location.origin}>
      <AppInternal />
    </RacwaInternalAuth>
  );
} else {
  App = () => <AppInternal />;
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
