import { atom } from "recoil";
import { RECOIL_PREFIX, SPARK_APP_LINKS, STORAGE_KEY_EXTERNAL_LINK } from "../constants";

const defaultSelected = SPARK_APP_LINKS.length > 0 ? SPARK_APP_LINKS[0].url : "";

const externalLinkState = atom<string>({
  key: `${RECOIL_PREFIX}_${STORAGE_KEY_EXTERNAL_LINK}`,
  default: defaultSelected,
});

export default externalLinkState;
