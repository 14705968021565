import { Box, Checkbox, Container, FormControlLabel, Grid, Typography } from "@mui/material";
import { useSetBackdrop } from "raci-react-library";
import { AppConfig } from "raci-react-library-npe";
import ExternalLinkSelector from "./components/ExternalLinkSelector";
import { LoginAsContactId } from "./components/LoginAsContactId";
import { LoginAsPolicyOwner } from "./components/LoginAsPolicyOwner";
import { useLaunchPage } from "./hooks/useLaunchPage";

export const LaunchPage: React.FC = () => {
  const setBackdrop = useSetBackdrop();
  const {
    onSubmitAsPolicyholder,
    onSubmitAsFindAllOwners,
    onOpenNewTabChange,
    onOpenLocalhostChange,
    logInAsContact,
    policyNumber,
    policyOwners,
    onPolicyNumberChange,
    claimNumber,
    claimOwnerInfo,
    onClaimNumberChange,
  } = useLaunchPage();

  return (
    <Grid container>
      <Grid item xs={5}>
        <Box m={2}>
          <Typography variant="h1">Test Launch Page for NPE</Typography>
          <LoginAsContactId
            onLoginAsContactId={(contactId: string) => {
              setBackdrop(true);
              logInAsContact(parseInt(contactId));
            }}
          />
          <Typography variant="h3">Log in as a policy owner</Typography>
          <LoginAsPolicyOwner
            identifierType="policyNumber"
            identifier={policyNumber}
            owners={policyOwners}
            onIdentifierChange={onPolicyNumberChange}
            onSubmitAsFindAllOwners={onSubmitAsFindAllOwners}
            onSubmitAsPolicyholder={onSubmitAsPolicyholder}
            logInAsContact={logInAsContact}
          />
          <LoginAsPolicyOwner
            identifierType="claimNumber"
            identifier={claimNumber}
            ownersHeading={claimOwnerInfo?.policyNumber}
            owners={claimOwnerInfo?.contacts}
            onIdentifierChange={onClaimNumberChange}
            onSubmitAsFindAllOwners={onSubmitAsFindAllOwners}
            onSubmitAsPolicyholder={onSubmitAsPolicyholder}
            logInAsContact={logInAsContact}
          />
        </Box>
      </Grid>
      <Grid item>
        <Container>
          <Box mt={2}>
            <Typography variant="h2">Feature toggles</Typography>
            <ExternalLinkSelector />
            <FormControlLabel control={<Checkbox onChange={onOpenLocalhostChange} />} label="Open in localhost" />
            <FormControlLabel control={<Checkbox onChange={onOpenNewTabChange} />} label="Open in new tab" />
          </Box>
        </Container>
        <Box mt={2}>
          <AppConfig showTitle={false} showOverrideToNumberSelector />
        </Box>
      </Grid>
    </Grid>
  );
};

export default LaunchPage;
