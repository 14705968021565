import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { HTTP_QUERY_STRING_CLAIM_NUMBER, HTTP_QUERY_STRING_POLICY_NUMBER, useSetBackdrop } from "raci-react-library";
import {
  DEFAULT_SHIELD_ENVIRONMENT,
  HTTP_QUERYSTRING_TESTLAUNCHPAGE_CRM_ID,
  HTTP_QUERYSTRING_TESTLAUNCHPAGE_FEATURE_TOGGLES,
  HTTP_QUERYSTRING_TESTLAUNCHPAGE_OVERRIDE_TO_NUMBER,
  HTTP_QUERYSTRING_TESTLAUNCHPAGE_SHIELD_ENVIRONMENT,
  HTTP_QUERYSTRING_TESTLAUNCHPAGE_SOURCE_URL,
  useFeatureToggles,
  useOverrideToNumber,
  useShieldEnvironment,
} from "raci-react-library-npe";
import { ClaimContactsResponse, PolicyContactDto, PolicyContactDtoPosition } from "raci-testlaunchpage-clientproxy";
import { ChangeEvent, ChangeEventHandler, useState } from "react";
import { useRecoilState } from "recoil";
import externalLinkState from "../../../shared/ExternalLinkState";
import useApiClient from "../../../shared/useApiClient";

export type IdentifierType = "policyNumber" | "claimNumber";

export interface FormProps {
  onSubmitAsPolicyholder: (indentifierType: IdentifierType) => void;
  onSubmitAsFindAllOwners: (identifierType: IdentifierType) => void;
  onOpenNewTabChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onOpenLocalhostChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  logInAsContact: (identifier: number) => void;
  policyNumber: string;
  policyOwners: PolicyContactDto[];
  onPolicyNumberChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  claimNumber: string;
  claimOwnerInfo?: ClaimContactsResponse;
  onClaimNumberChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export function getFrontDoorSubdomain(shieldEnvironment: string) {
  if (shieldEnvironment === DEFAULT_SHIELD_ENVIRONMENT) {
    const defaultShieldEnvironment = process.env.REACT_APP_DEFAULT_SHIELD_ENVIRONMENT;
    if (!defaultShieldEnvironment) {
      throw new Error("Failed to construct frontdoor subdomain, default shield environment variable was undefined");
    }

    shieldEnvironment = process.env.REACT_APP_DEFAULT_SHIELD_ENVIRONMENT!;
  }

  const env = shieldEnvironment.toLowerCase().replace("shield", "");
  return `b2c${env}`;
}

export function getExternalUrl(externalLink: string, shieldEnvironment: string, openInLocalhost: boolean) {
  const appPath = new URL(externalLink).pathname;

  if (openInLocalhost) {
    return new URL(`http://localhost:3000${appPath}`);
  }

  if (process.env.REACT_APP_ENVIRONMENT !== "UAT") {
    return new URL(externalLink);
  }

  const frontDoorSubdomain = getFrontDoorSubdomain(shieldEnvironment);
  return new URL(`https://${frontDoorSubdomain}.ractest.com.au${appPath}`);
}

export const useLaunchPage = (): FormProps => {
  const client = useApiClient();
  const setBackdrop = useSetBackdrop();
  const appInsights = useAppInsightsContext();

  const [policyNumber, setPolicyNumber] = useState<string>("");
  const [policyOwners, setPolicyOwners] = useState<PolicyContactDto[]>([]);

  const [claimNumber, setClaimNumber] = useState<string>("");
  const [claimOwnerInfo, setClaimOwnerInfo] = useState<ClaimContactsResponse>();

  const [openInNewTab, setOpenInNewTab] = useState(false);
  const [openInLocalhost, setOpenInLocalhost] = useState(false);
  const [externalLink] = useRecoilState(externalLinkState);
  const { selected: shieldEnvironment } = useShieldEnvironment();
  const { selectedOverrideToNumber } = useOverrideToNumber();
  const [toggles] = useFeatureToggles();

  const onSubmitAsPolicyholder = async (identifierType: IdentifierType) => {
    identifierType === "policyNumber"
      ? await retrievePolicyOwners(policyNumber, true)
      : await retrieveClaimContactInfo(claimNumber, true);
  };

  const onSubmitAsFindAllOwners = async (identifierType: IdentifierType) => {
    identifierType === "policyNumber"
      ? await retrievePolicyOwners(policyNumber, false)
      : await retrieveClaimContactInfo(claimNumber, false);
  };

  const onPolicyNumberChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPolicyNumber(event.target.value.trim());
  };

  const onClaimNumberChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setClaimNumber(event.target.value.trim());
  };

  const onOpenNewTabChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOpenInNewTab(event.target.checked);
  };

  const onOpenLocalhostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOpenInLocalhost(event.target.checked);
  };

  const logInWithCrm = async (crmId: string) => {
    const encodedToggles = window.btoa(JSON.stringify(toggles));

    const url = getExternalUrl(externalLink, shieldEnvironment, openInLocalhost);

    if (policyNumber !== "") {
      url.searchParams.append(HTTP_QUERY_STRING_POLICY_NUMBER, policyNumber);
    }

    if (claimNumber) {
      url.searchParams.append(HTTP_QUERY_STRING_CLAIM_NUMBER, claimNumber);
    }

    url.searchParams.append(HTTP_QUERYSTRING_TESTLAUNCHPAGE_SOURCE_URL, document.location.href);
    url.searchParams.append(HTTP_QUERYSTRING_TESTLAUNCHPAGE_CRM_ID, crmId);
    url.searchParams.append(HTTP_QUERYSTRING_TESTLAUNCHPAGE_SHIELD_ENVIRONMENT, shieldEnvironment);
    selectedOverrideToNumber &&
      url.searchParams.append(HTTP_QUERYSTRING_TESTLAUNCHPAGE_OVERRIDE_TO_NUMBER, selectedOverrideToNumber);
    url.searchParams.append(HTTP_QUERYSTRING_TESTLAUNCHPAGE_FEATURE_TOGGLES, encodedToggles);
    console.log(`Redirecting to: ${url}`);

    appInsights.trackEvent({
      name: "Logging in to external site",
      properties: {
        externalLink: externalLink,
      },
    });

    window.open(url, openInNewTab ? "_blank" : "_self");
  };

  const logInAsContact = async (identifier: number) => {
    console.log("Getting CRM for: " + identifier);

    try {
      const response = await client.getCrmId(identifier.toString());

      if (response.result?.crmId) {
        console.log(`Found CRM: ${response.result?.crmId}`);
        await logInWithCrm(response.result?.crmId);
      }
    } catch (exception) {
    } finally {
      setBackdrop(false);
    }
  };

  const retrievePolicyOwners = async (policyNumber: string, logInAsPolicyholder: boolean) => {
    if (policyNumber) {
      setBackdrop(true);

      try {
        const contactsResponse = await client.getPolicyContacts(policyNumber);

        const policyholder = contactsResponse?.result?.contacts?.find(
          (contact) => contact.position === PolicyContactDtoPosition.Owner,
        );

        if (!policyholder) {
          setBackdrop(false);
          console.error(`Failed to get policy: ${JSON.stringify(contactsResponse)}`);
          return;
        }

        if (logInAsPolicyholder && policyholder?.contactId) {
          await logInAsContact(policyholder.contactId);
        } else {
          setPolicyOwners(contactsResponse.result.contacts ?? []);
        }
      } catch (exception) {
        console.error(exception);
      } finally {
        setBackdrop(false);
      }
    }
  };

  const retrieveClaimContactInfo = async (claimNumber: string, LoginAsPolicyholder: boolean) => {
    if (claimNumber) {
      setBackdrop(true);

      try {
        const response = await client.getClaimContacts(claimNumber);

        setClaimOwnerInfo(response.result);

        const owner = response.result.contacts?.find((c) => c.position === PolicyContactDtoPosition.Owner);

        if (!owner) {
          console.error("Failed to get claim contact info", response);
          return;
        }

        if (LoginAsPolicyholder && owner.contactId) {
          await logInAsContact(owner.contactId);
        } else {
          setClaimOwnerInfo(response.result);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setBackdrop(false);
      }
    }
  };

  return {
    onSubmitAsPolicyholder,
    onSubmitAsFindAllOwners,
    onOpenNewTabChange,
    onOpenLocalhostChange,
    logInAsContact,
    policyNumber,
    policyOwners,
    onPolicyNumberChange,
    claimNumber,
    claimOwnerInfo,
    onClaimNumberChange,
  };
};
