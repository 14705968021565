import { faKey, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { RacwaTextInput } from "@racwa/react-components";
import { useSetBackdrop } from "raci-react-library";
import { PolicyContactDto } from "raci-testlaunchpage-clientproxy";
import { FormProps, IdentifierType } from "../../hooks/useLaunchPage";

export interface LoginAsPolicyOwnerProps
  extends Pick<FormProps, "onSubmitAsPolicyholder" | "onSubmitAsFindAllOwners" | "logInAsContact"> {
  identifierType: IdentifierType;
  identifier: string;
  owners?: PolicyContactDto[];
  ownersHeading?: string;
  onIdentifierChange: FormProps["onPolicyNumberChange"];
}

export const LoginAsPolicyOwner = ({
  identifierType,
  identifier,
  owners,
  ownersHeading,
  onIdentifierChange,
  onSubmitAsFindAllOwners,
  onSubmitAsPolicyholder,
  logInAsContact,
}: LoginAsPolicyOwnerProps) => {
  const setBackdrop = useSetBackdrop();

  return (
    <Box sx={{ mb: "8px" }}>
      <Box>
        <FormControl>
          <Typography variant="h4">{`With ${
            identifierType === "policyNumber" ? "policy" : "claim"
          } number`}</Typography>
          <RacwaTextInput
            id={identifierType}
            data-testid={identifierType}
            title={identifierType}
            placeholder={`${identifierType === "policyNumber" ? "Policy" : "Claim"} Number`}
            type="text"
            required={true}
            value={identifier}
            onChange={onIdentifierChange}
          />
        </FormControl>
      </Box>
      <Box>
        <Grid container spacing={1}>
          <Grid item>
            <Button
              id={`submit-${identifierType}`}
              data-testid={`submit-${identifierType}`}
              type="button"
              color="primary"
              variant="contained"
              startIcon={<FontAwesomeIcon icon={faKey} />}
              onClick={() => onSubmitAsPolicyholder(identifierType)}
            >
              Log in as Policyholder
            </Button>
          </Grid>
          <Grid item>
            <Button
              id={`findOwners-${identifierType}`}
              data-testid={`findOwners-${identifierType}`}
              type="button"
              color="secondary"
              variant="contained"
              onClick={() => onSubmitAsFindAllOwners(identifierType)}
              startIcon={<FontAwesomeIcon icon={faUserGroup} />}
            >
              Find all Owners
            </Button>
          </Grid>
        </Grid>

        {owners && owners.length > 0 && (
          <Box mt={2}>
            <TableContainer>
              <Table>
                {ownersHeading && (
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={3}>{ownersHeading}</TableCell>
                    </TableRow>
                  </TableHead>
                )}
                <TableHead>
                  <TableRow>
                    <TableCell>Position</TableCell>
                    <TableCell>Contact ID</TableCell>
                    <TableCell>Log In</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {owners.map((owner, index) => (
                    <TableRow key={index}>
                      <TableCell>{owner.position}</TableCell>
                      <TableCell>{owner.contactId}</TableCell>
                      <TableCell>
                        <Button
                          id={`loginAsOwner-${identifierType}-${index}`}
                          data-testid={`loginAsOwner-${identifierType}-${index}`}
                          color="primary"
                          size="small"
                          startIcon={<FontAwesomeIcon icon={faKey} />}
                          onClick={() => {
                            setBackdrop(true);
                            logInAsContact(owner?.contactId ?? 0);
                          }}
                        >
                          Login
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>
    </Box>
  );
};
