import { useAccount } from "@racwa/react-adb2c";
import {
  createMsalClient,
  HTTP_HEADER_KEY_RACWA_NPE_AUTHORIZATION,
  useFeatureToggles,
  useShieldEnvironment,
} from "raci-react-library-npe";
import { TestLaunchPageApiClient } from "raci-testlaunchpage-clientproxy";
import { useMemo } from "react";
import {
  DEFAULT_SHIELD_ENVIRONMENT,
  HTTP_HEADER_KEY_SHIELD_ENVIRONMENT,
  OAUTH_AUTHORITY,
  OAUTH_CLIENT_ID,
} from "../constants";
import { enableInternalAuth } from "../EnableInternalAuth";
import { applyFeatureToggles } from "../useFeatureToggles";

export const useApiClient = (): TestLaunchPageApiClient => {
  const npeInstance = enableInternalAuth
    ? createMsalClient(OAUTH_CLIENT_ID, OAUTH_AUTHORITY, window.location.origin)
    : null;
  const npeAccount = useAccount({ clientId: OAUTH_CLIENT_ID });

  const { selected: selectedShieldEnv } = useShieldEnvironment();
  const [toggles] = useFeatureToggles();

  return useMemo(() => {
    const fetchWrapper = async (url: RequestInfo, init?: RequestInit): Promise<Response> => {
      let npeToken = "";

      if (npeInstance) {
        const loginRequestBase = {
          scopes: [],
          redirectUri: "/",
        };
        const npeLoginRequest = {
          ...loginRequestBase,
          account: npeAccount,
        };

        try {
          const tokenResponse = await npeInstance.acquireTokenSilent(npeLoginRequest);
          npeToken = tokenResponse.idToken;
        } catch (ex) {
          await npeInstance.acquireTokenRedirect(npeLoginRequest);
        }
      }

      let headers: any = { ...init?.headers };
      const enabledToggles = toggles?.filter((toggle: any) => toggle.enabled === true) ?? [];

      headers = applyFeatureToggles(headers, enabledToggles);

      if (selectedShieldEnv && selectedShieldEnv !== DEFAULT_SHIELD_ENVIRONMENT) {
        headers[HTTP_HEADER_KEY_SHIELD_ENVIRONMENT] = selectedShieldEnv;
      }
      if (npeToken) {
        headers[HTTP_HEADER_KEY_RACWA_NPE_AUTHORIZATION] = npeToken;
      }
      const response = await fetch(url, {
        ...init,
        headers,
      });
      return response;
    };

    return new TestLaunchPageApiClient(process.env.REACT_APP_API_BASE_URL || "", {
      fetch: fetchWrapper,
    });
  }, [npeAccount, npeInstance, selectedShieldEnv, toggles]);
};

export default useApiClient;
