import { MenuItem } from "@mui/material";
import { RacwaSelect } from "@racwa/react-components";
import { useRecoilState } from "recoil";
import { SPARK_APP_LINKS } from "../../../../shared/constants";
import externalLinkState from "../../../../shared/ExternalLinkState";
import { ExternalLink } from "../../../../types";

// TODO SPK-2474 - Handle multiple UAT envs per Spark flow in Spark Test Launch Page
export const ExternalLinkSelector: React.FC = () => {
  const [externalLink, setExternalLink] = useRecoilState(externalLinkState);

  return SPARK_APP_LINKS.length > 0 ? (
    <RacwaSelect
      id="inp-npe-select-external-site"
      label="External site"
      fullWidth
      value={externalLink}
      onChange={(e: any) => setExternalLink(e.target.value as string)}
    >
      {SPARK_APP_LINKS.map((item: ExternalLink) => (
        <MenuItem key={item.url} value={item.url} selected={item.url === externalLink}>
          {item.displayName}
        </MenuItem>
      ))}
    </RacwaSelect>
  ) : null;
};

export default ExternalLinkSelector;
