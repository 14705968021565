import { faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, FormControl, Typography } from "@mui/material";
import { RacwaTextInput } from "@racwa/react-components";
import { useState } from "react";

export interface LoginAsContactIdProps {
  onLoginAsContactId: (contactId: string) => void;
}

export const LoginAsContactId: React.FC<LoginAsContactIdProps> = ({ onLoginAsContactId }) => {
  const [contactId, setContactId] = useState("");

  const setValidContactId = (contactId: string) => {
    const onlyNumbers = contactId.replace(/\D/g, "");
    setContactId(onlyNumbers);
  };

  return (
    <Box sx={{ mb: "32px" }}>
      <FormControl>
        <Typography variant="h3">Log in as contact</Typography>
        <RacwaTextInput
          id="contactId"
          data-testid="contactId"
          title="contactId"
          placeholder="Contact Id"
          type="text"
          required={true}
          value={contactId}
          onChange={(e) => setValidContactId(e.target.value)}
        />
      </FormControl>
      <Box>
        <Button
          id="loginAsContact"
          data-testid="loginAsContact"
          type="button"
          color="primary"
          variant="contained"
          startIcon={<FontAwesomeIcon icon={faKey} />}
          onClick={() => onLoginAsContactId(contactId)}
        >
          Log in as Contact
        </Button>
      </Box>
    </Box>
  );
};
