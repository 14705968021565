import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
  config: {
    connectionString: `InstrumentationKey=${process.env.REACT_APP_INSTRUMENTATION_KEY};IngestionEndpoint=${process.env.REACT_APP_INSIGHTS_URL}`,
    extensions: [reactPlugin as any],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

// Don't load the AppInsights if the connection string will not be valid
if (process.env.REACT_APP_INSTRUMENTATION_KEY && process.env.REACT_APP_INSIGHTS_URL) {
  appInsights.loadAppInsights();
  appInsights.trackPageView();
}

export { reactPlugin, appInsights };
